/* EVENTS SECTION */
#events {
  background-color: #fafcfd;
  padding: 50px;
  text-align: center;
  margin-top: 150px;
}

.events-intro {
  margin-bottom: 20px;
}

.event-card-container {
  display: flex;
  justify-content: center; /* Center the cards container */
  flex-wrap: wrap; /* Allow the cards to wrap to the next line if needed */
  gap: 20px; /* Space between the cards */
}

.event-title {
  color: #170827;
  text-decoration: underline #ceb378 5px;
  margin-bottom: 20px;
  font-size: 2rem; /* Use rem for responsive font size */
}

.event-card {
  flex: 0 0 calc(33.333% - 20px); /* Flex basis calculation to fit three cards accounting for gap */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  background-color: white;
  margin-bottom: 20px; /* Spacing at the bottom of each card */
  padding: 20px; /* Space inside the card */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.event-text h3 {
  color: #170827;
  margin: 10px 0;
}

.event-text a {
  color: #ab8e4f;
}

.event-p {
  width: 90%;
  margin: auto;
  font-size: 1.25rem; /* Use rem for responsive font size */
}

#events img {
  max-width: 100%; /* This will make the image responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 16px; /* Optional: rounded corners */
}

.view-all-events-button,
.event-text .register-button {
  background-color: #ab8e4f;
  color: #fafcfd;
  border: none;
  border-radius: 10px;
  padding: 0.625rem 1.25rem; /* Use rem for responsive padding */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block; /* This will prevent the button from stretching */
  margin-top: 20px;
  font-size: 1rem; /* Use rem for responsive font size */
  text-decoration: none; /* Remove the underline from the link */
}

.view-all-events-button:hover,
.event-text .register-button:hover {
  background-color: #ceb378;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .event-card {
    flex: 0 0 calc(50% - 20px); /* Two cards per row on medium screens */
  }
}

@media screen and (max-width: 480px) {
  .event-card {
    flex: 0 0 calc(100% - 20px); /* One card per row on small screens */
  }
}
