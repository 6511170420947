/* Main CSS */
html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)),
    url("https://landezine-award.com/wp-content/uploads/2020/04/UTSC-20.jpg")
      no-repeat center center fixed;
  background-size: cover;
}

/* Responsive adjustments for the background image */
@media screen and (max-width: 768px) {
  body {
    background-size: cover; /* Ensure the background image fits the screen */
  }
}

.container {
  position: relative;
  margin-top: 100px;
}

.container img {
  display: block;
  width: 100%;
  background-attachment: fixed;
}
