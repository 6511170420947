/* src/styles/mentalhealth.css */
#mental-health {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    height: 100%;
    width: 100%;
    /* width: auto;
    margin: auto; */
    background-color: #e6e6e6;
  }
  .emergency-contacts {
    margin-bottom: 10px;
  }
  
  .mental-health-content {
    text-align: center;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    /* color: #170827; */
  }
  
  .mental-health-title {
    margin-top: 50px;
    color: #170827;
    font-size: 2.5em;
    margin-bottom: 20px;
    text-decoration: underline #ceb378 5px;
  }
  
  .mental-health-contacts a{
    font-size: 1.2em;
    cursor: pointer;
    margin-top: 0;
    margin-right: 45px;
    color: #333;
    text-decoration: none;
  }


  .mental-health-contacts a:hover {
    text-decoration: underline;
  }

  .mental-health-section h3 {
    color: #333;  /**/
    font-size: 1.75em;
  }
  .community_orgs_header {
    margin-bottom: 10px;
  }

  .mental-health-section li {
    list-style: none;
  }

  .resource-container {
    max-width: 800px;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    gap: 10px;
    place-items: center;
    margin-bottom: 30px;
  }
  .resource-container h3 {
    color: #253e6d;
    text-decoration: underline #ceb378 5px;
  }

  .resource-container li {
    margin-bottom: 15px;
  }

  .resource-container ul {
    margin-right: 15px;
  }


  .on-campus-resource {
    border: 1px solid #ccc;
    width: 90%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    min-height: 200px;
    max-height: fit-content;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }

  .on-campus-resource li {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  .on-campus-resource li a {
    text-decoration: none;
    color: #333;
  }
  .on-campus-resource li a:hover {
    cursor: pointer;
    color: #170827;
    text-decoration: underline;
  }
  .external-resource li {
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .external-resource {
    border: 1px solid #ccc;
    width: 90%;
    background-color: white;
    border-radius: 7px;
    text-align: center;
    align-items: center;
    min-height: 200px;
    max-height: fit-content;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  /* SLIDE DECK */
  .selfcare-title {
    margin-top: 50px;
    font-size: 2em;
    margin-bottom: 20px;
    text-decoration: underline #ceb378 5px;
  }
  .slideshow-container-mh {
    max-width: 90%;
    position: relative;
    aspect-ratio: 16 / 9;
    width: 750px;
    min-width: 400px;
    min-height: 400px;
    border-radius: 16px;
    border: 5px solid #ceb378;
  }

  .banner-msg {
    text-align: center;
    width: 80%;
    line-height: 1.5;
  }

  h4 {
    font-weight: 100;
    font-size: 0.8rem;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .mySlides {
    /* padding: 0; */
    /* display: flex; Ensure content aligns properly */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 0; */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
  }

  .mySlides div {
    /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
    height: auto;
    justify-self: center;
    align-self: center;
    text-align: center;
    width: 100%;
  }

  .mySlides div p {
    text-align: center;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }

  .dua-arabic {
    width: 95%;
    font-size: 1.8rem;
  }

  .dua-transliteration {
    font-size: 1.2rem;
    color: rgb(101, 100, 100);
    width: 90%;
  }

  .dua-translation {
    width: 90%;
    font-size: 1.2rem;
    color: rgb(101, 100, 100)
  }

  .slide0 p {
    font-size: 0.9rem;
  }
  .slide0 .dua-arabic {
    font-size: 1.4rem;
  }

  .prev,
  .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: #888;
  font-weight: bold;
  font-size: 1.25rem; /* Use rem for responsive font size */
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  position: absolute;
  right: 0;
  z-index: 10;
  border-radius: 3px 0 0 3px;
}

.prev {
  position: absolute;
  left: 0;
  z-index: 10;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.dot-container {
  text-align: center;
  padding: 20px;
  background: #ddd;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.language-btn {
  margin-top: 10px;
  font-size: 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/*Trusted Organizations*/

.organizations-container {
  display: grid;
  max-width: 70%;
  width: 800px;
  min-width: 40%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  place-items: center;
  gap: 2rem;
  margin-bottom: 10px;
  padding: 0px 20px;
}

.chap-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  min-width: 40%;
  max-width: 80%;
  /* border: 1px solid #ccc; */
  margin-bottom: 20px;
}


#org {
  border: 3px solid #ceb378;
  aspect-ratio: 1 /1;
  width: 150px;
  border-radius: 50%;
}
#big-org {
  background-color: white;
}
.org2 {
  background-color: white;
}


#big-org {
  border: 3px solid #ceb378;
  aspect-ratio: 1 /1;
  width: 220px;
  border-radius: 50%;
}

#big-org img {
  border-radius: 50%;
  width: 100%;
}

.chap-container h4 {
  font-size: 1.7rem;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 10px;
}

.organizations-container div h4 {
  font-size: 1.3rem;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 10px;
}
.organizations-container div p {
  font-size: 1rem;
  text-align: center;
}

.organizations-container a {
  text-decoration: none;
  color: purple;
}
.chap-container a {
  text-decoration: none;
  color: purple;
}
.organizations-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
}

#org img {
  border-radius: 50%;
  width: 100%;
}

.org1 {
  background-color: rgb(7, 68, 58);
}.org6 {
  background-color: white;
}
.org5 {
  background-color: rgb(57, 143, 136);
}

.services {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Insurance-div {
  width: 90%;
  max-width: 800px;
  padding: 0 20px
}

.Insurance-div span {
  color: #170827;
  font-weight: bold;
}

.greenshield-link {
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.greenshield-link:hover {
  color: purple;
}

.services p {
  color: #170827;
  font-size: 1.1rem;
  font-weight: bold;
}
@media (max-width:2200px) {
  .organizations-container {
    max-width: 80%;
  }
  #org {
    width: 100px;
  }
  #big-org {
    width: 180px;
  }
  .organizations-container div h4 {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .organizations-container div p {
    font-size: 0.8rem;
    text-align: center;
  }
  .chap-container h4 {
    font-size: 1.5rem;
  }
}


/* Responsive adjustments */
@media screen and (max-width: 768px) {
  /* #mental-health {
    padding: 30px;
  } */
  .slideshow-container-mh {
    /* aspect-ratio: 4 / 3;  */
    width: 100%;
    max-width: 90%;
    min-height: 400px;
  }
  .organizations-container {
    max-width: 90%;
  }
  .organizations-container div {
    width: 180px;
  }
  #org {
    width: 90px;
  }
  .mySlides {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .external-resource li {
    font-size: 0.9rem;
  }
  .on-campus-resource li {
    font-size: 0.9rem;
  }
  .dua-arabic {
    font-size: 1.4rem;
  }
  .dua-translation,
  .dua-transliteration {
    font-size: 1rem;
  }
  
  .prev,
  .next {
    font-size: 1rem;
  }

  .slide0 p {
    font-size: 0.75rem;
  }
  .slide0 .dua-arabic {
    font-size: 0.9rem;
  }
}

@media (max-width: 490px) {
  .slideshow-container-mh {
    aspect-ratio: 4 / 3; 
  }

  .dua-arabic {
    font-size: 1.3rem;
  }
  .dua-translation,
  .dua-transliteration {
    font-size: 0.9rem;
  }
  .slide0 p {
    font-size: 0.7rem;
  }
  .slide0 .dua-arabic {
    font-size: 0.8rem;
  }
}