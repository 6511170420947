/* src/styles/programsAndEvents.css */
#programs-events-page {
  padding: 50px 0;
}

.section-title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  font-size: 2.5em;
  color: #170827;
  text-decoration: underline #ceb378 5px;
}

.section-description {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #555;
}

.announcements-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
}

.announcements-table th,
.announcements-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.announcements-table th {
  background-color: #253e6d;
  color: white;
}

.announcements-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.announcements-table tr:hover {
  background-color: #ddd;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.calendar-container iframe {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
}
