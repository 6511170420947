#prayer-areas-page {
  background-color: #fafcfd;
  padding: 50px;
  text-align: center;
  margin-top: 20px; /* Add top margin */
}

.event-title {
  color: #170827;
  text-decoration: underline #ceb378 5px;
  margin-bottom: 20px;
  font-size: 2em;
  font-family: Arial, sans-serif; /* Ensure consistent font */
}

.event-description {
  font-size: 1em; /* Ensure consistent font size */
  color: #170827;
  margin-bottom: 20px;
  font-family: Arial, sans-serif; /* Ensure consistent font */
}

.calendar-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

iframe {
  max-width: 100%;
  border-radius: 16px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .event-title {
    font-size: 1.5em;
  }

  .event-description {
    font-size: 0.9em;
  }

  iframe {
    width: 100%;
    max-height: 250px;
  }
}
