/* src/styles/announcements.css */
#announcements-page {
  padding: 50px;
  background-color: #f9f9f9;
  margin-top: 200px;
}

.section-title {
  color: #170827;
  text-decoration: underline #ceb378 5px;
  margin-bottom: 20px;
  font-size: 2em;
  text-align: center;
}

.section-description {
  text-align: center;
  margin-bottom: 20px;
  color: black;
  font-size: 1.1em;
}

.announcements-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.announcements-table th,
.announcements-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.announcements-table th {
  background-color: #253e6d;
  color: white;
  text-align: left;
}

.announcements-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.announcements-table tr:hover {
  background-color: #ddd;
}
