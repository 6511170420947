#osp_banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    height: 100%;
    width: 100%;
    background-color: #e6e6e6;
    box-sizing: border-box;
    padding: 20px 0;
}

.h2 {
    font-size: 2em;
    color: #170827;
    margin-top: 0;
    margin-bottom: 70px;
}   

.title {
    text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.5em;
  color: #170827;
  /* margin-bottom: 10px; */
  text-decoration: underline #ceb378 5px;
}
.osp-banner-content {
    display: grid;
    width: 90%;
    height: auto;
    place-items: center;
    grid-template-columns: repeat(auto-fit, minmax(260px,1fr));
    gap: 15px;
    /* border: 1px solid #ccc; */
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-top: 30px;
    
}
.challenge-container {
    width: 260px;
    aspect-ratio: 1 /1;
    /* border: 1px solid #ccc; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 2px;
}

.challenge-container h3 {
    font-size: 1.1rem;
}

.challenge-img {
    aspect-ratio: 1 / 1;
    width: 250px;
    border-radius: 8px;  
      
}

.challenge-img img {
    width: 100%;
    height: 100%;
    border-radius: 8px; 
}

.current-challenge {
    box-shadow: 0 0 14px 12px #ceb378;
}

.challenge-complete {
    background-color: #ceb378;
    border-radius: 8px;
    padding: 8px 12px;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .challenge-img {
        width: 190px;
    }

    .challenge-container {
        width: 200px;
    }
    .osp-banner-content {
        grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
    }
}
