#announcements {
  display: block;
  text-align: center;
  padding: 100px;
  width: auto;
  margin: auto;
  font-size: 1.25rem; /* Use rem for responsive font size */
}

.slideshow-container {
  position: relative;
  background: #f1f1f1;
}

.mySlides {
  display: none;
  padding: 80px;
  text-align: center;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: #888;
  font-weight: bold;
  font-size: 1.25rem; /* Use rem for responsive font size */
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev {
  position: absolute;
  left: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.dot-container {
  text-align: center;
  padding: 20px;
  background: #ddd;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

q {
  font-style: italic;
}

.author {
  color: cornflowerblue;
}

.view-all-button {
  background-color: #ceb378;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 20px 30px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
  font-size: 1.25rem; /* Use rem for responsive font size */
}

.view-all-button:hover {
  background-color: #253e6d;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  #announcements {
    padding: 50px;
  }

  .mySlides {
    padding: 40px;
  }

  .prev,
  .next {
    font-size: 1rem;
  }

  .view-all-button {
    font-size: 1rem;
    padding: 15px 25px;
  }
}
