#footer {
  background-color: #170827;
  color: #fafcfd;
  font-family: Arial, sans-serif;
  padding: 40px 0;
  text-align: center;
  overflow: auto;
  margin-top: 200px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left,
.footer-right,
.footer-center {
  flex: 1;
}
.footer-center {
  min-width: 400px;
}

.footer-logo {
  max-width: 200px;
  margin-bottom: 20px;
}

.signup-button {
  background-color: #ceb378;
  color: #170827;
  border: none;
  padding: 0.625rem 1.25rem; /* Use rem for responsive padding */
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  font-size: 1rem; /* Use rem for responsive font size */
  margin-top: 10px;
  text-decoration: none;
}

.signup-button:hover {
  background-color: #ab8e4f;
}

.social-links {
  margin-top: 20px;
}

.social-icon {
  display: inline-block;
  margin-right: 10px;
  color: white;
  font-size: 2rem; /* Use rem for responsive font size */
  text-decoration: none;
}

.social-icon:hover {
  color: #ceb378;
}

.linktree-link {
  display: inline-block;
  color: #fafcfd;
  font-size: 1.125rem; /* Use rem for responsive font size */
  margin-top: 20px;
  text-decoration: none;
}

.linktree-link:hover {
  color: #ceb378;
}

.sponsors img {
  max-width: 100px;
  margin: 0 10px;
}

.footer-right {
  padding: 10px;
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo {
    max-width: 150px;
  }

  .signup-button {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }

  .social-icon {
    font-size: 1.5rem;
  }

  .linktree-link {
    font-size: 1rem;
  }

  .sponsors img {
    max-width: 80px;
    margin: 10px;
  }
}
