
#gallery-home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    height: 100%;
    width: 100%;
    background-color: #e6e6e6;
}

.gallery-home-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 50px;
}

.gallery-title {
    color: #170827;
    text-decoration: underline #ceb378 5px;
    margin-bottom: 20px;
    font-size: 2rem; /* Use rem for responsive font size */
  }

.gallery-year-container {
    max-width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    padding: 15px;
    box-sizing: border-box;
    height: auto;
    min-height: fit-content;
    margin-bottom: 50px;
    margin-top: 50px;
}

.gallery-preview-btn-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}

.gallery-preview-btn-container button {
    padding: 10px;
    font-size: 1.6rem;
    border: none;
    color: #170827;
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;
}

.gallery-preview-container {
    width: 90%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(auto-fit, minmax(450px,1fr));
    gap: 20px;
    height: auto;
    /* border: 1px solid; */
    border-radius: 16px;
    padding: 15px 10px;
    box-sizing: border-box;
}

.preview {
    aspect-ratio: 16 / 9;
    width: 450px;
    border-radius: 8px;
    cursor: pointer;
}

.preview img:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.9);
}

.preview img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.gallery-year-btn {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: #68656b;
  font-weight: bold;
  cursor: pointer;
}
.selected-btn {
  text-decoration: underline #ceb378 5px;
  color: #170827;
}

@media (max-width: 1200px) {
    .gallery-year-container {
        width: 90%;
    }
    .gallery-preview-container {
        grid-template-columns: repeat(auto-fit, minmax(400px,1fr));
    }
    .preview {
        width: 400px;
    }
}

@media (max-width: 1100px) {
    .gallery-preview-container {
        grid-template-columns: repeat(auto-fit, minmax(280px,1fr));
        gap: 20px;
    }
    .preview {
        width: 280px;
    }
    .gallery-title {
        margin-bottom: 5px;
    }
}

@media (max-width: 808px) {
    .gallery-preview-container {
        grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
        gap: 20px;
        padding: 15px 25px;
        box-sizing: border-box;
    }
    .preview {
        width: 250px;
    }
    .gallery-preview-btn-container button {
        font-size: 1.2rem;
    }
}
