.osp-section {
  background-color: #f9f9f9;
  padding: 40px 0;
  margin-top: 300px;
}

#osp {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  height: 100%;
  width: 100%;
  background-color: #e6e6e6;
}

.osp-container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.section-title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.5em;
  color: #170827;
  margin-bottom: 10px;
  text-decoration: underline #ceb378 5px;
}

.title-2 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.2em;
  color: #170827;
  margin-bottom: 15px;
  text-decoration: underline #ceb378 5px;
}

.osp-container h3 {
  color: #333;
  font-size: 1.75em;
  margin-top: 20px;
}

.osp-container p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.osp-container blockquote {
  border-left: 4px solid #0056b3;
  padding-left: 20px;
  font-style: italic;
  margin: 20px 0;
}

.osp-history .osp-path-image {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: block;
}

#oop {
  background-color: green;
}
.osp-content {
  text-align: center;
  width: 90%;
  padding: 0px 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
  margin-bottom: 50px;
  /* color: #170827; */
  
}

/* TESTING */
.grid-section {
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  height: auto;
  gap: 1.5rem;
}

.grid-img {
  aspect-ratio: 16 / 9;
  width: 100%;
  /* border: 1px solid #ccc; */
}

.grid-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.grid-text {
  text-align: center;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16 / 9;
  /* border: 1px solid #ccc; */
  color: #170827;
}

.grid-text h2 {
  font-size: 30px;
}

.grid-text p {
  font-size: 1.3rem;
  width: 90%;
  color: #555;
}

@media (max-width: 1515px) {
  .grid-section {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 1rem;
  }
}

@media (max-width: 1400px) {
  .grid-section {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem;
  }
  .grid-text {
    width: 380px;
  }
  .grid-text p {
    font-size: 1rem;
  }
}

@media (max-width: 1100px) {
  .grid-section {
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }
  .grid-text {
    width: 350px;
  }
}

@media (max-width: 960px) {
  .grid-section {
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    gap: 0.6rem;
  }
  .grid-text {
    width: 90%;
  }
  .grid-text p {
    font-size: 1.3rem;
  }
}

/* Responsiveness */
@media (max-width: 768px) {
  .section-title {
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
  }

  .slideshow-container-osp {
    aspect-ratio: 4 / 3;
    max-width: 90%;
    width: 100%;
  }

  .osp-year-btn {
    font-size: 1rem;
  }

  .osp-section h3 {
    text-align: center;
  }

  .grid-section {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
  .grid-text {
    width: 400px;
  }
}
